<template>
  <div class="lock-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
let validRoomNo = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
    let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
    if (!reg.test(value)) {
      callback(new Error("房号格式不对"));
    } else {
      callback();
    }
  }
};
let validBiaobianhao = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //支持输入大小写字母，数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,16}$/;
    if (!reg.test(value)) {
      callback(new Error("表编号格式不对"));
    } else {
      callback();
    }
  }
};
let validMAC = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //支持输入大小写字母，数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("MAC格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      dialogVisible: false,
      selectList: [{
        label:'全部',
        value:'',
      }],
      dialogList: {
        id: "",
        Name: "",
        mac: "",
        ip: "",
        apartmentID: "",
        location: ""
      },
      opt: {
        title: "智能水表列表",
        isShowEmptyText:{
         on(){
              _this.$router.push("/main/smartMeter/batch/add/0");
            }
        },
        search: [
          {
            key: "type",
            type: "select-no-filterable",
            label: "类型",
            opt: {
              list: []
            }
          },
          {
            key: "apartmentId",
            type: "select",
            label: "房源",
            opt: {
              list: []
            }
          },
          // {
          //   key: "xuhao",
          //   label: "序号"
          // },
          {
            key: "roomNo",
            label: "房号",
             maxlength: 24,
              rules: [{
                validator: validRoomNo,
                trigger: ['blur']
              }]
          },
          {
            key: "Mac",
            label: "Mac",
            maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
          },
          // {
          //   key: "state",
          //   label: "状态",
          //   type: "select",
          //   opt: {
          //     list: [
          //       {
          //         label: "全部",
          //         value: null
          //       },
          //       {
          //         label: "在线",
          //         value: "1"
          //       },
          //       {
          //         label: "离线",
          //         value: "2"
          //       },
          //     ]
          //   }
          // },
          // {
          //   type: "Export",
          //   url: this.EXport + "/finance-service/leaseOrder/exportOrder"
          //   // on: row => {
          //   //   _this.opt.search.forEach((item, index) => {
          //   //     console.log(item);
          //   //   });

          //   //   _this.onExport(row);
          //   // }
          // }
        ],
        columns: [
          {
            label: "MAC",
            key: "mac"
          },
          { label: "Sn", key: "Name" },
          { label: "房间号", key: "apartmentName" },
          { label: "小区", key: "location", width: 150 },
          { label: "楼栋", key: "lastUseTime" },
          { label: "上次数据", key: "lastUseTime" },
          { label: "当前数据", key: "lastUseTime" },
          {
            label: "状态",
            key: "state",
          },
          {
            label: "操作",
            key: "action",
            type: "action",
            opt: {
              list: [
                {
                  label: "停用",
                  on(row) {
                    console.log(row);
                    _this.dialogVisible = true;
                    _this.dialogList.Name = row.Name;
                    _this.dialogList.id = row.id;
                    _this.dialogList.mac = row.mac;
                    _this.dialogList.ip = row.ip;
                    // _this.dialogList.apartmentID = row.apartmentId;
                    _this.dialogList.location = row.location;
                  }
                },
                {
                  label: "查看历史",
                  on(row) {
                    _this.$router.push("/main/smartMeter/log/" + row.id);
                  }
                },
                // {
                //   label: "自动抄表",
                //   on(row) {
                //     _this.$router.push("/main/BluetoothKey/log/" + row.id);
                //   }
                // }
              ]
            }
          }
        ],
        buttons: [
          {
            type: 0,
            name: "批量手动抄表",
            icon: "el-icon-circle-plus-outline",
            on() {
              _this.$router.push("/main/smartMeter/batch/add/0");
            }
          },
        ]
      }
    };
  },
  created() {
    console.log("lock-list created!!");
    
    if(this.cache.getLS("userInfo")["roleType"] == "1"){
        this.opt.buttons=[];
        this.opt.isShowEmptyText='';
        // this.opt.search.splice(5,1);

    }
  },
  activated() {
      this.getApartmentList();
  },
  methods: {
    onGet(opt) {
       let data={};
      data.data=[]
      opt.cb(data);
      // let dto = {
      //   pageNum: opt.skip,
      //   pageSize: opt.limit,
      //   ...opt.searchForm
      // };
      // this.post("device-service/L2/page/query", dto, {
      //   isUseResponse: true
      // }).then(res => {
      //   // for (let i = 0; i < res.data.data.length; i++) {
      //   //   let item = res.data.data[i];
      //   //   item.Name = ["蓝牙网关", "W7", "L2蓝牙公钥"][item.type - 1];
      //   //   item.lockState = ["等待开锁", "开锁成功", "开锁失败"][item.lockState];
      //   //   item.state = ["未借出", "已借出"][item.state];
      //   //   item.action = [true, true];
      //   //   item.apartmentName ='全部';
      //   // }
      //   res.data=[]
      //   opt.cb(res.data);
      // });
    },
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"]
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.id] = data.name;
          });
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          // this.selectList = res;
          this.opt.search[1]["opt"]["list"] = res;
        }
      );
    },
    onRester() {
      this.dialogVisible = false;
    },
  }
};
</script>
<style lang="scss">
.alertTiltes {
  .ul_div {
    display: flex;
    line-height: 40px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #666666;
    margin-bottom: 10px;
  }
  .ul_li_one {
    width: 85px;
    height: 40px;
    text-align: right;
  }
  .ul_li_scond {
    width: 160px;
    height: 40px;
    text-align: center;
  }
  .ul_li_thrwe {
    width: 310px;
    text-align: left;
    .img_div {
      width: 70px;
      height: 70px;
      float: left;
      border: 1px solid #dcdfe6;
      margin-right: 5px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }
  }
  .textarea {
    width: 310px;
    height: 80px;
    border-color: #dcdfe6;
    color: #303133;
    textarea {
      width: 100%;
      height: 100%;
      resize: none;
    }
  }
}


</style>
